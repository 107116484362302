import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../setup/store';

export interface RioProfile {
    sub?: string;
    azp?: string;
    account?: string;
    givenName?: string;
    familyName?: string;
    name?: string;
    locale?: string;
    email?: string;
}

export const accountType = { CARRIER: 'CARRIER', BUYER: 'BUYER', SUPPLIER: 'SUPPLIER' } as const;

export type AccountType = (typeof accountType)[keyof typeof accountType];

export interface LoginState {
    hasUserSessionEverExpired: boolean;
    userProfile: RioProfile | null;
    userSessionExpired: boolean;
    accountType: AccountType;
}

const initialState: LoginState = {
    hasUserSessionEverExpired: false,
    userProfile: null,
    userSessionExpired: false,
    accountType: 'CARRIER',
};

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        userProfileObtained: (state, action: PayloadAction<RioProfile>) => {
            state.userProfile = action.payload;
        },
        userSessionExpired: (state) => {
            state.hasUserSessionEverExpired = true;
            state.userSessionExpired = true;
        },
        userSessionRenewed: (state) => {
            state.userSessionExpired = false;
        },
        accountTypeObtained: (state, action: PayloadAction<'CARRIER' | 'BUYER' | 'SUPPLIER'>) => {
            state.accountType = action.payload;
        },
    },
});

export const { userProfileObtained, userSessionExpired, userSessionRenewed, accountTypeObtained } = loginSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getUserProfile = (state: RootState) => state.login.userProfile;
export const getUserAccount = (state: RootState) => state.login.userProfile?.account;

export const hasUserSessionEverExpired = (state: RootState) => state.login.hasUserSessionEverExpired;
export const isUserSessionExpired = (state: RootState) => state.login.userSessionExpired;
export const getAccountType = (state: RootState) => state.login.accountType;

export const getProfileUserId = (state: RootState): string | undefined =>
    getUserProfile(state)?.sub?.split(':')[1] ?? undefined;

export default loginSlice.reducer;
